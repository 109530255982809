.ant-divider-inner-text {
  font-weight: bold;
}

#overview-tabs {
  margin: -24px;

  .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
    min-height: calc(100vh - 86px);
  }

  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-tab {
      border: none;
    }

    .close-tab {
      color: red;
    }

    .ant-tabs-nav-more {
      background: #fff;
    }
  }
}

#overview-menu {
  margin-left: -15px;
  width: 100%;
}

#overview-detail {
  width: calc(100% - 200px);
  margin-left: 10px;

  .table-description {
    padding: 20px;
  }

  .image-overlay {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(4px);
  }
}
