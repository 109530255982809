#underconstruction {
  min-height: 100vh;

  h1 {
    margin: auto;
    text-transform: uppercase;
    text-align: center;

    small {
      display: block;
    }
  }
}

#main {
  .site-sidebar-menu {
    overflow: auto;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;

    .logo {
      margin: 17.5px 25.5px;
      transition: all 0.15s ease-out;

      img {
        height: 40px;
        transition: all 0.15s ease-out;
      }
    }

    ul {
      li {
        margin-bottom: 0px;
        margin-top: 0px;
        line-height: 45px;
      }
    }
  }

  .site-sidebar-menu.ant-layout-sider-collapsed {
    .logo {
      height: 0;
      margin: 0;

      img {
        height: 0;
      }
    }
  }

  .site-layout {
    min-height: calc(100vh - 48px);

    .site-content {
      margin: 24px 16px;
      padding: 24px;
    }
  }
}

.card-no-padding {
  .ant-card-body {
    padding: 0px;
  }
}
