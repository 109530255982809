path:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

text {
  .points {
    text-shadow: 2px 2px 4px #ffffff;
    font-family: sans-serif;
    fill: #000;
  }
}

circle {
  cursor: pointer;

  &:hover {
    stroke: #ddd;
  }
}

.points:hover ~ text.points {
  fill: #eb455b;
}
